<template>
  <div class="content_body InventoryInquire" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" @submit.native.prevent>
            <el-form-item v-if="purchaseStorage.length>1" label="仓库/门店">
              <el-select v-model="EntityID" clearable filterable placeholder="请选择仓库" :default-first-option="true" @change="handleSearchInquireClick" @clear="handleSearchInquireClick">
                <el-option v-for="item in purchaseStorage" :key="item.ID" :label="item.EntityName" :value="item.ID">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品名称">
              <el-input v-model="searchProductName" placeholder="请输入产品名称、别名" clearable @keyup.enter.native="handleSearchInquireClick" @clear="handleSearchInquireClick"></el-input>
            </el-form-item>
            <el-form-item label="产品分类">

              <el-cascader
                @change="handleSearchInquireClick" 
                @clear="handleSearchInquireClick"
                :options="classifyList"
                :show-all-levels="true"
                clearable filterable
                :props="cascaderProps"
                v-model="PCategoryID"
                placeholder="请选择产品分类"
              ></el-cascader>

              <!-- <el-select v-model="PCategoryID" placeholder="请选择产品分类" :default-first-option="true" clearable filterable @change="handleSearchInquireClick" @clear="handleSearchInquireClick">
                <el-option :label="item.Name" :value="item.ID" v-for="(item,index) in  classifyList" :key="index">{{item.Name}}</el-option>
              </el-select> -->

            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="handleSearchInquireClick" v-prevent-click>搜索</el-button>
              <el-button type="primary" @click="downloadStockStockExcel" :loading="downloadLoading" v-prevent-click v-if="isExport">导出</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-checkbox class="marbm_5" v-model="IsDisplayZero_Search" @change="handleSearchInquireClick">仅显示有库存</el-checkbox>
        </el-col>
      </el-row>
    </div>

    <el-table class="martp_10" size="small" :data="InquireList">
      <el-table-column prop="ProductName" label="产品名称">
        <template slot-scope="scope">
          {{scope.row.ProductName}}
          <span v-if="scope.row.Alias" class="font_13 color_999">({{scope.row.Alias}})</span>
        </template>
      </el-table-column>
      <el-table-column prop="PCategoryName" label="产品分类"></el-table-column>
      <el-table-column prop="Specification" label="产品规格"></el-table-column>
      <el-table-column prop="UnitName" label="单位"></el-table-column>
      <el-table-column prop="Quantity" label="实物库存">
      </el-table-column>
      <el-table-column label="操作" width="210">
        <template slot-scope="scope">
          <el-button v-if="EntityID=='' && purchaseStorage.length>1" type="primary" size="small" v-prevent-click @click="checkStockDistribution(scope.row)">库存分布</el-button>
          <el-button type="primary" size="small" v-prevent-click @click="checkProductDetail(scope.row)">出入库明细</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pad_15 text_right">
      <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
    </div>
    <!-- 出入库明细 -->
    <el-dialog custom-class="outAndInDetailClass" title="出入库明细" :visible.sync="outAndInDetailVisible" width="1100px">

      <el-row class="border pad_10  radius5">
        <el-col :span="12">
          <div>{{ProductInfo.ProductName}}
            <span v-if="ProductInfo.Alias" class="color_gray font_13">({{ProductInfo.Alias}})</span>
          </div>
          <div v-if="ProductInfo.Specification" class="color_gray font_12"><span>规格：{{ProductInfo.Specification}}</span></div>
        </el-col>
        <el-col :span="12">
          <span style="float:right;">单位：{{ProductInfo.UnitName}}</span>
        </el-col>
      </el-row>

      <el-form label-width="100px" size="small" class="outAndInDetailFormClass martp_10">
        <el-row>
          <el-col :span="6" v-if="purchaseStorage.length>1">
            <el-form-item label="仓库/门店：" prop="EntityID">
              <el-select size="small" value-key="ID" v-model="detailFrom.EntityID" filterable placeholder="请选择仓库/门店" @change="inquireStockeData" clearable>
                <el-option value-key="ID" v-for="item in purchaseStorage" :key="item.ID" :label="item.EntityName" :value="item.ID"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="出入库类型：">
              <el-select v-model="detailFrom.InventoryType" clearable filterable placeholder="请选择出入库类型" :default-first-option="true" @change="inquireStockeData">
                <el-option v-for="item in InventoryTypes" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期筛选：">
              <el-date-picker v-model="detailFrom.DateTime" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" clearable @change="inquireStockeData"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-table size="small" :data="detail_list" tooltip-effect="light">
        <el-table-column prop="BillID" label="单据号"></el-table-column>
        <el-table-column prop="EntityName" label="仓库/门店"></el-table-column>
        <el-table-column prop="InventoryType" label="出入库类型"></el-table-column>
        <el-table-column prop="MinimumUnitQuantity" label="出入库">
          <template slot-scope="scope">
            <span class="color_green" v-if="scope.row.MinimumUnitQuantity>0">+{{scope.row.MinimumUnitQuantity}}</span>
            <span class="color_red" v-else-if="scope.row.MinimumUnitQuantity<0">{{scope.row.MinimumUnitQuantity}}</span>
            <span v-else>{{scope.row.MinimumUnitQuantity}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="Quantity" label="剩余量">
          <template slot-scope="scope">
            {{scope.row.StockQuantity}}
          </template>
        </el-table-column>
        <el-table-column prop="BillDate" label="制单时间"></el-table-column>
        <el-table-column prop="EmployeeName" label="操作人"></el-table-column>
      </el-table>

      <div class="pad_15 text_right">
        <el-pagination background v-if="detailPaginations.total > 0" @current-change="handleDetailProductCurrentChange" :current-page.sync="detailPaginations.page" :page-size="detailPaginations.page_size" :layout="detailPaginations.layout" :total="detailPaginations.total"></el-pagination>
      </div>

    </el-dialog>

    <!-- 库存分布 -->
    <el-dialog custom-class="" title="库存分布" :visible.sync="checkStockVisible" width="800px">
      <el-row class="border pad_10 marbm_10 radius5">
        <el-col :span="12">
          <div>{{ProductInfo.ProductName}}
            <span v-if="ProductInfo.Alias" class="color_gray font_13">({{ProductInfo.Alias}})</span>
          </div>
          <div v-if="ProductInfo.Specification" class="color_gray font_13"><span>规格：{{ProductInfo.Specification}}</span></div>
        </el-col>
        <el-col :span="12">
          <span style="float:right;">单位：{{ProductInfo.UnitName}}</span>
        </el-col>
      </el-row>
      <el-checkbox class="marbm_5" v-model="IsDisplayZero" @change="changeIsDisplayZero">仅显示有库存</el-checkbox>
      <el-table size="small" :data="stockData">
        <el-table-column prop="EntityName" label="仓库/门店"></el-table-column>
        <el-table-column prop="Quantity" label="实物库存"></el-table-column>
      </el-table>

      <div class="pad_15 text_right">
        <el-pagination background v-if="stockPaginations.total > 0" @current-change="handleStockDetailProductCurrentChange" :current-page.sync="stockPaginations.page" :page-size="stockPaginations.page_size" :layout="stockPaginations.layout" :total="stockPaginations.total"></el-pagination>
      </div>

    </el-dialog>

  </div>
</template>

<script>
import APIInquire from "@/api/PSI/Inventory/Inquire";
import APIStorage from "@/api/PSI/Purchase/storage";
import APICategory from "@/api/PSI/Product/productCategory";
import API from "@/api/PSI/Inventory/inventoryDetail";
import permission from "@/components/js/permission.js";
export default {
  name: "InventoryInquire",
  /**  Vue 实例的数据对象**/
  data() {
    return {
      loading: false,
      isExport:'',
      downloadLoading:false,
      outAndInDetailVisible: false,
      checkStockVisible: false,
      searchProductName: "",
      InquireList: [],
      EntityID: "",
      PCategoryID: "",
      classifyList: [], // 分类列表
      purchaseStorage: [], // 仓库列表
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next, jumper", // 翻页属性
      }, //需要给分页组件传的信息

      detailPaginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next, jumper", // 翻页属性
      }, //需要给分页组件传的信息

      stockPaginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next, jumper", // 翻页属性
      }, //需要给分页组件传的信息
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() ? true : false;
        },
      },
      ProductInfo: "",
      detail_list: "",
      stockData: "",
      InventoryType: "",
      InventoryTypes: [
        "采购入库",
        "盘盈入库",
        "要货入库",
        "调拨入库",
        "其他入库",
        "盘平",
        "盘亏出库",
        "销售出库",
        "配送出库",
        "调拨出库",
        "领料出库",
        "报损出库",
        "其他出库",
        "加盟商采购入库",
        "加盟商采购出库",
      ],
      detailFrom: {
        EntityID: "",
        InventoryType: "",
        DateTime: "",
      },
      IsDisplayZero: true,
      IsDisplayZero_Search: true,

      cascaderProps: {
        value: "ID",
        label: "Name",
        children: "Child",
        emitPath: false,
        expandTrigger: "hover"
      }, // 级联选择器配置项
    };
  },
  /**  方法集合  */
  methods: {
    /**  搜索  */
    handleSearchInquireClick() {
      var that = this;
      that.paginations.page = 1;
      that.getStockListNetwork();
    },
    /**  页码修改  */
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.getStockListNetwork();
    },


    /**  筛选库存明细  */
    inquireStockeData() {
      let that = this;
      that.detailPaginations.page = 1;
      that.get_stockList_inventoryDetail_netWork();
    },
    /**  查看产品出入库明细  */
    checkProductDetail(row) {
      let that = this;
      that.ProductInfo = row;
      that.detailFrom.EntityID = that.EntityID;
      // that.detailProductID = row.ProductID
      // that.detailFrom.DateTime = [dateUtil.formatDate.format(new Date(y, m, 1),"YYYY-MM-DD"),dateUtil.formatDate.format(new Date(),"YYYY-MM-DD")];
      that.get_stockList_inventoryDetail_netWork();
    },
    /**  明细修改分页  */
    handleDetailProductCurrentChange(page) {
      let that = this;
      that.detailPaginations.page = page;
      that.get_stockList_inventoryDetail_netWork();
    },
    /** 查看库存分布   */
    checkStockDistribution(row) {
      let that = this;
      that.ProductInfo = row;
      that.getStock_productEntity_netWork();
    },
    /** 修改查看库存 状态  仅显示有库存  */
    changeIsDisplayZero(row) {
      let that = this;
      that.stockPaginations.page = 1;
      that.getStock_productEntity_netWork();
    },
    /**  库存分布分页  */
    handleStockDetailProductCurrentChange(page) {
      let that = this;
      that.stockPaginations.page = page;
      that.getStock_productEntity_netWork();
    },

    /**  4.1.采购入库列表  */
    getStockListNetwork: function () {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.paginations.page,
        ProductName: that.searchProductName,
        EntityID: that.EntityID,
        PCategoryID: that.PCategoryID,
        IsDisplayZero: !that.IsDisplayZero_Search,
      };
      APIInquire.getStockList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.InquireList = res.List;
            that.paginations.page_size = res.PageSize;
            that.paginations.total = res.Total;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  4.4.仓库列表  */
    getStorageEntityNetwork: function () {
      var that = this;
      that.loading = true;
      var params = {};
      APIStorage.getpurchaseStorageEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.purchaseStorage = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取产品分类列表
    getProductCategory: function () {
      var that = this;
      that.loading = true;
      // var params = {
      //   Name: "",
      //   Active: true,
      // };
      APICategory.getValidProductCategory()
        .then((res) => {
          if (res.StateCode == 200) {
            that.classifyList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    /**     */
    get_stockList_inventoryDetail_netWork: function () {
      var that = this;
      var params = {
        PageNum: that.detailPaginations.page,
        ProductID: that.ProductInfo.ProductID,
        InventoryType: that.detailFrom.InventoryType,
        EntityID: that.detailFrom.EntityID,
        StartDate:
          that.detailFrom.DateTime == null ? "" : that.detailFrom.DateTime[0],
        EndDate:
          that.detailFrom.DateTime == null ? "" : that.detailFrom.DateTime[1],
      };
      API.get_stock_inventoryDetail(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.detail_list = res.List;
            that.detailPaginations.page_size = res.PageSize;
            that.detailPaginations.total = res.Total;
            that.outAndInDetailVisible = true;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    /**  库存分布   */
    getStock_productEntity_netWork: function () {
      var that = this;
      var params = {
        IsDisplayZero: !that.IsDisplayZero,
        ProductID: that.ProductInfo.ProductID,
        PageNum: that.stockPaginations.page,
      };
      APIInquire.getStock_productEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.stockData = res.List;
            that.stockPaginations.page_size = res.PageSize;
            that.stockPaginations.total = res.Total;
            that.checkStockVisible = true;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    // 导出库存
    downloadStockStockExcel(){
      const that =this
      let params = {
          ProductName: that.searchProductName,
          EntityID: that.EntityID,
          PCategoryID: that.PCategoryID,
          IsDisplayZero: !that.IsDisplayZero_Search,
        };
        that.downloadLoading = true;
        APIInquire.stockStockExcel(params)
          .then((res) => {
            console.log(11,res);
            this.$message.success({
              message: "正在导出",
              duration: "4000",
            });
            const link = document.createElement("a");
            let blob = new Blob([res], { type: "application/octet-stream" });
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.download = "库存明细.xlsx"; //下载的文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .finally(function () {
            that.downloadLoading = false;
          });
    },
  },
    beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log(to);
      vm.isExport=permission.permission(
        to.meta.Permission,
        "PSI-Inventory-Inquire-Export"
      );
    });
  },
  /**  实例被挂载后调用  */
  mounted() {
    var that = this;
    that.isExport=permission.permission(
        that.$route.meta.Permission,
        "PSI-Inventory-Inquire-Export"
      );
    that.getStockListNetwork();
    that.getStorageEntityNetwork();
    that.getProductCategory();
  },
};
</script>

<style lang="scss">
.InventoryInquire {
  .outAndInDetailClass {
    .outAndInDetailFormClass {
      .el-form-item__label {
        font-size: 13px !important;
      }
      .el-form-item__content {
        font-size: 13px !important;
      }
      .el-form-item {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
